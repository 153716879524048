export class ButtonHelper {

    private toggle(button: HTMLElement, forceShowButton: boolean): void {
        let spinnerWrapper = window.o_order.utils.next(button, 'order_js_button_spinner');
        const spinner = document.createElement('div');

        spinner.classList.add("p_loader100");

        const styleSpinner = (): void => {
            const sw = spinnerWrapper as HTMLElement
            sw.style.width = button.offsetWidth + "px";
            sw.style.height = button.offsetHeight + "px";
            sw.style.float = button.style.float;
            sw.style.verticalAlign = 'middle';
            sw.style.display = 'table-cell';
            sw.style.margin = 'auto';
            spinner.style.margin = 'auto';
        }

        const isShowSpinner = (): boolean => {
            return button.style.display !== 'none' && !forceShowButton;
        }

        if (isShowSpinner() && !spinnerWrapper) {
            spinnerWrapper = document.createElement('div');
            spinnerWrapper.classList.add("order_js_button_spinner");
            spinnerWrapper.appendChild(spinner);
            styleSpinner();
            button.style.display = 'none';
            button.insertAdjacentHTML('afterend', spinnerWrapper.outerHTML);
        } else if (isShowSpinner()) {
            styleSpinner();
            button.style.display = 'none';
        } else {
            button.style.display = 'block';
            if (spinnerWrapper) {
                const sw = spinnerWrapper as HTMLElement
                sw.style.display = 'none';
            }
        }
    }

    toggleLoading(buttons: HTMLElement | NodeListOf<HTMLElement>, forceShowButton: boolean = false): void {
        if (buttons) {
            if ('length' in buttons) {
                Array.from(buttons).forEach((button) => {
                    this.toggle(button, forceShowButton)
                });
            } else {
                this.toggle(buttons, forceShowButton);
            }
        }
    }

    static initialize(): void {
        const o_order = window.o_order ?? {};
        o_order.common = o_order.common ?? {};
        o_order.common.buttons = o_order.common.buttons ?? new ButtonHelper();
    }
}

