import {Action, ActionName, DataContainer, Feature} from "@otto-ec/tracking-bct";

export class ActionTracking {

    private randomEventMergeId(): string {
        const crypto = window.crypto;
        const validChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        return Array(10).fill(null)
            .reduce(x => x + validChars[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * validChars.length)], '');
    }

    createEventMergeId(generateId: boolean): string {
        if (generateId) {
            return this.randomEventMergeId();
        }

        return this.getEventMergeId() ?? this.randomEventMergeId();
    }

    getEventMergeId(): string | undefined {
        return document.querySelector<HTMLElement>('.or_js_eventMergeId')?.dataset.eventMergeId;
    }

    submitMerge(data: DataContainer, features: Feature[] = []): void {
        window.otto.tracking.submitMerge(data, features);
    }

    submitEventMerge(action: Action, generateId: boolean = false): void {
        const eventMergeId = this.createEventMergeId(generateId);
        window.otto.tracking.submitEventMerge(eventMergeId, {}, action);
    }

    submitEvent(action: Action, labels: DataContainer = {}): void {
        window.otto.tracking.submitEvent(labels, action);
    }

    submitMove(action: Action, labels: DataContainer = {}): void {
        window.otto.tracking.submitMove(labels, action);
    }

    submitDummyEventMerge(name: ActionName, eventMergeId: string): void {
        window.otto.tracking.submitEventMerge(eventMergeId, {}, {name: name, features: []});
    }

    static initialize(): void {
        const o_order = window.o_order ?? {};
        o_order.tracking = o_order.tracking ?? new ActionTracking();
    }
}
