import '../common/common.js'
import {Utils} from "./Utils.js";
import {BasketChange} from "./BasketChange.js";
import {SettingsService} from './SettingsService.js';
import {L33t} from "./L33t.js";
import {FireBaseBridge} from "./FireBaseBridge.js";
import {TrackingWrapper} from "./TrackingWrapper.js";
import {ButtonHelper} from "./ButtonHelper.js";
import {ActionTracking} from "./ActionTracking.js";

Utils.initialize();
BasketChange.initialize();
SettingsService.initialize();
L33t.initialize();
FireBaseBridge.initialize();
TrackingWrapper.initialize();
ButtonHelper.initialize();
ActionTracking.initialize();