import {DataContainer} from "@otto-ec/tracking-bct";

export class TrackingWrapper {

    static initialize(): void {
        const o_order = window.o_order ?? {};

        o_order.trackingWrapper = o_order.trackingWrapper ?? new TrackingWrapper();
    }

    failSafeSubmitEvent(data: DataContainer): void {
        try {
            if (!window.o_order.settings.service.isEnabled('EPIC_TRACKING_QUIETSCHTEST')) {
                window.otto.tracking.submitEvent(data);
            }
        } catch (e) {
            console.log("Failed to call window.otto.tracking.submitEvent", e);
        }
    }
}