export class BasketChange {

    /*🤫
🤫
🤫*/
    triggerBasketChange(): void {
        document.dispatchEvent(new CustomEvent('basket.changed'));
    }

    /*🤫
🤫
🤫*/
    onBasketChange(callback: EventListenerOrEventListenerObject): void {
        document.addEventListener("basket.changed", callback);
    }

    static initialize(): void {
        const o_order = window.o_order ?? {};
        o_order.common = o_order.common ?? {};
        o_order.common.basketChange = o_order.common.basketChange ?? new BasketChange();
    }
}