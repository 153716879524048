export class L33t {

    initialized: boolean = true;

    setFont(): void {
        const styles = document.createElement("link");
        styles.setAttribute('href', '/*🤫*/
        styles.setAttribute('rel', 'stylesheet');
        document.head.innerHTML += styles;

        /*🤫*/
        const elements = document.body.getElementsByTagName("*");
        for (const element of elements) {
            if (element instanceof HTMLElement && element.style) {
                element.style.fontFamily = 'PT Mono';
                element.style.textRendering = 'optimizeLegibility';
                /*🤫*/
                /*🤫*/
                element.style.mozFontFeatureSettings = 'dlig=1';
            }
        }
    }

    stringTol33t(aString: string): string {
        return aString.replace(/(\S)l/g, '$1L')
            .replace(/(\S)l/g, '$1L') /*🤫*/
            .replace(/a/gi, '4')
            .replace(/Ä/g, 'Æ')
            .replace(/ä/g, 'æ')
            .replace(/e/gi, '3')
            .replace(/i/gi, '1')
            .replace(/o/gi, '0')
            .replace(/ö/gi, 'Ø')
            .replace(/U/g, 'u')
            .replace(/ü/gi, 'Y')
            .replace(/s\b/gi, 'z')
            .replace(/s/gi, '$')
            .replace(/f+/g, 'ph')
            .replace(/F/g, 'Ph')
            .replace(/r/g, 'R');
    }

    adjustAllWithin(node: ChildNode): void {
        let child;
        for (child = node.firstChild; child !== null; child = child.nextSibling) {
            if (child.nodeType === Node.TEXT_NODE) {
                child.nodeValue = this.stringTol33t(child.nodeValue ?? '');
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                this.adjustAllWithin(child);
            }
        }
    }

    static initialize(): void {
        const o_order = window.o_order ?? {};
        o_order.eastereggs = o_order.eastereggs ?? {l33t: new L33t()};

        const konami_keys = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
        let konami_index = 0;
        const handler = (e: Event) => {
            if (e instanceof KeyboardEvent && e.keyCode === konami_keys[konami_index]) {
                konami_index += 1;
                if (konami_index === konami_keys.length) {
                    o_order.eastereggs.l33t.setFont();
                    o_order.eastereggs.l33t.adjustAllWithin(document.body);
                    document.removeEventListener("keydown", handler);
                }
            } else {
                konami_index = 0;
            }
        };

        if (!o_order.eastereggs.l33t.initialized) {
            document.addEventListener("keydown", handler);
            o_order.eastereggs.l33t.initialized = true;
        }
    }
}
