export class FireBaseBridge {

    emitEvent(eventName: string, itemId: string, itemName: string, productBaseClass: string, quantity: number): void {
        window.o_global.eventQBus.emit("ft-apps.firebase.eventTracking", {
            "event_name": eventName,
            "event_params": {
                "item_id": itemId,
                "item_name": itemName,
                "item_category": productBaseClass,
                "quantity": quantity
            }
        });
    }

    emitEventWithTotal(eventName: string, itemId: string, itemName: string, productBaseClass: string, quantity: number, total: number, orderId: string): void {
        window.o_global.eventQBus.emit("ft-apps.firebase.eventTracking", {
            "event_name": eventName,
            "event_params": {
                "item_id": itemId,
                "item_name": itemName,
                "item_category": productBaseClass,
                "quantity": quantity,
                "value": total,
                "currency": "EUR",
                "transaction_id": orderId
            }
        });
    }

    emitAddToBasketEvent(event: AddToBasketEvent): void {
        try {
            this.emitEvent("add_to_cart", event.articleNumber, event.itemName, event.itemCategory, event.addedQuantity);
        } catch (e) {
            if (window.o_global.debug.status().activated) {
                console.error(e);
            }
        }
    }

    static initialize(): void {
        const o_order = window.o_order ?? {};

        o_order.firebaseBridge = o_order.firebaseBridge ?? new FireBaseBridge();
    }

}

interface AddToBasketEvent {
    articleNumber: string;
    itemName: string;
    itemCategory: string;
    addedQuantity: number;
}
