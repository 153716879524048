export class SettingsService {

    private readonly settings: string[];

    constructor() {
        this.settings = this.initSettings();
    }

    private initSettings(): string[] {
        const settingsPerClass = document.getElementsByClassName('or_js_settings');
        let settingsElement;
        if (settingsPerClass.length > 0) {
            settingsElement = settingsPerClass[settingsPerClass.length - 1];
        }
        const settingsString = settingsElement?.getAttribute('data-settings') ?? '';

        return settingsString.split(',');
    }

    isEnabled(settingName: string): boolean {
        return this.settings.includes(settingName);
    }

    static initialize(): void {
        const o_order = window.o_order ?? {};
        o_order.settings = o_order.settings ?? {};

        if (!o_order.settings.service) {
            o_order.settings.service = new SettingsService();
        }
    }
}
